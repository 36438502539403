<script setup lang="ts">
const appConfig = useAppConfig()

const viewport = useViewport()

const { t, locale }: any = useI18n()
const localePath = useLocalePath()

const route = useRoute()

const url = useRequestURL()

// const slug = route.params.slug ?? ''
const slug = computed(() => _get(route.params.slug ?? [], 0, ''))

const routeNameCheck = computed(() => _includes(String(route.name), 'index'))

const host = await useGetHost()

const token: any = useCookie('token')

const authenticated = useAuthenticated()

const sitedata: any = useSiteData()

const cartKey = sitedata.value?.site_type === 4 ? 'cart-shop' : 'cart'

const sitedataMenus = computed(() => sitedata.value?.category?.length ? [...[{ name: t('homepage') }], ...sitedata.value?.category] : [])

const accountItems = ref<any>([])

if (authenticated.value) {
  const items = [{
    label: t('account'),
    icon: 'i-heroicons-user-solid',
    click: async () => {
      await navigateTo(localePath('/account'))
    },
  }]

  if (sitedata.value?.hascart) {
    items.push({
      label: t('orders'),
      icon: 'i-heroicons-shopping-bag-16-solid',
      click: async () => {
        await navigateTo(localePath('/orders'))
      },
    })
  }

  if (sitedata.value?.invest) {
    items.push({
      label: t('investment'),
      icon: 'i-heroicons-currency-dollar',
      click: async () => {
        await navigateTo(localePath('/investments'))
      },
    })
  }

  accountItems.value = [
    ...[items],
    ...[
      [{
        label: t('signout'),
        icon: 'i-heroicons-arrow-left-on-rectangle',
        click: async () => {
          await $fetch('/api/logout', { method: 'post', headers: { domain: host, token: token.value } })
          await Promise.all([
            (token.value = null),
            localStorage.removeItem(cartKey),
            appConfig.locales.forEach((item: any) => { localStorage.removeItem(`${cartKey}-${item}`) }),
          ])
          await navigateTo({ path: localePath('/') }, { external: true })
        },
      }],
    ],
  ]

  // accountItems.value = [
  //   [{
  //     label: t('account'),
  //     icon: 'i-heroicons-user-solid',
  //     click: async () => {
  //       await navigateTo(localePath('/account'))
  //     },
  //   }, {
  //     label: t('orders'),
  //     icon: 'i-heroicons-shopping-bag-16-solid',
  //     click: async () => {
  //       await navigateTo(localePath('/orders'))
  //     },
  //   }],
  //   [{
  //     label: t('signout'),
  //     icon: 'i-heroicons-arrow-left-on-rectangle',
  //     click: async () => {
  //       await $fetch('/api/logout', { method: 'post', headers: { domain: host, token: token.value } })
  //       await Promise.all([
  //         (token.value = null),
  //         localStorage.removeItem(cartKey),
  //         appConfig.locales.forEach((item: any) => { localStorage.removeItem(`${cartKey}-${item}`) }),
  //       ])
  //       await navigateTo({ path: localePath('/') }, { external: true })
  //     },
  //   }],
  // ]
}
else {
  accountItems.value = [
    [{
      label: t('login'),
      icon: 'i-heroicons-chevron-right-16-solid',
      click: async () => {
        await navigateTo({ path: localePath('/login'), query: { redirect: url.href } })
      },
    }],
    [{
      label: t('register'),
      icon: 'i-heroicons-chevron-right-16-solid',
      click: async () => {
        await navigateTo(localePath('/register'))
      },
    }],
  ]
}
</script>

<template>
  <div
    class="hidden z-40 peer-checked:flex w-11/12 mx-auto lg:mx-0 flex-col
                justify-end items-center gap-y-8 p-6
                rounded-xl bg-white lg:flex
                lg:gap-y-0 lg:gap-x-2 lg:p-0
                lg:flex-row lg:bg-transparent lg:w-10/12"
  >
    <div class="block w-full lg:w-max print:hidden">
      <ul
        v-if="sitedataMenus && sitedataMenus?.length > 0"
        class="text-gray-600 font-medium flex flex-col lg:flex-row gap-y-5 lg:gap-y-0 gap-x-6 px-3 text-center"
      >
        <li
          v-for="(item, index) in sitedataMenus"
          :key="`FYWmQa${index}`"
        >
          <NuxtLinkLocale
            :to="item?.type === 'external' ? item?.url : (item?.type === 'internal' ? localePath(item?.url) : (item?.slug ? `/${item?.slug}` : '/'))"
            :target="item?.type === 'external' ? '_blank' : ''"
            :hreflang="locale"
            :class="(item?.slug === slug || (!item?.slug && routeNameCheck)) ? 'md:px-3' : 'md:px-3 group'"
          >
            <div
              :class="(item?.slug === slug || (!item?.slug && routeNameCheck)) ? 'relative' : 'relative group'"
              :style="(item?.slug === slug || (!item?.slug && routeNameCheck)) ? `color: ${sitedata?.primary_color ?? '#00701a'};` : ''"
            >
              <span
                :class="(item?.slug === slug || (!item?.slug && routeNameCheck)) ? '' : 'group-hover:text-slate-700'"
              >
                {{ item?.name }}
              </span>
            </div>
          </NuxtLinkLocale>
        </li>
      </ul>
    </div>

    <div class="w-full md:pl-5 gap-x-5 sm:w-max flex min-w-max flex-col sm:flex-row items-center print:hidden">
      <button
        v-if="viewport.isLessOrEquals('tablet') && sitedata?.layout !== 'singlepage' && sitedata?.hascart"
        type="button"
        :title="t('cart')"
        aria-label="cart-button1"
        class="flex items-center justify-start w-full py-3 px-5 rounded-xl text-center transition hover:bg-slate-700 active:bg-slate-800 focus:bg-slate-500 sm:w-max"
        :style="`background-color: ${sitedata?.primary_color ?? '#00701a'}`"
        @click="navigateTo(localePath('/cart'))"
      >
        <UIcon name="i-heroicons-shopping-cart-solid" class="text-white" />
        <span class="block text-white font-semibold ml-2">
          {{ t('cart') }}
        </span>
      </button>

      <UButton
        v-if="viewport.isGreaterThan('tablet') && sitedata?.layout !== 'singlepage' && sitedata?.hascart"
        :title="t('cart')"
        aria-label="cart-button2"
        icon="i-heroicons-shopping-cart-solid"
        size="xs"
        :style="`background-color: ${sitedata?.primary_color ?? '#00701a'}`"
        :ui="{ rounded: 'rounded-full' }"
        @click="navigateTo(localePath('/cart'))"
      />

      <UDropdown
        v-if="sitedata?.login_logout"
        :items="accountItems"
        :ui="{ item: { disabled: 'cursor-text select-text' } }"
        :popper="{ placement: 'bottom-start' }"
        class="py-2 my-5 sm:my-0"
      >
        <UAvatar icon="i-heroicons-user-solid" alt="" size="sm" />

        <span class="sm:hidden truncate text-lg px-3 text-gray-600">{{ t('account') }}</span>

        <template #account="{ item }">
          <div class="text-left">
            <p>
              Signed in as
            </p>
            <p class="truncate font-medium text-gray-600">
              {{ item.label }}
            </p>
          </div>
        </template>

        <template #item="{ item }">
          <span class="truncate text-base">{{ item.label }}</span>

          <UIcon :name="item.icon" class="flex-shrink-0 h-4 w-4 text-gray-400 ms-auto" />
        </template>
      </UDropdown>

      <LangCurrency />
    </div>
  </div>
</template>
